.nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }

.nopadding-left {
    padding-left: 0 !important;
}

.margin-top {
    margin-top: 1rem !important;
}