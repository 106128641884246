.navbar {
    height: 64px;
}

.circledName {
    width: 32px;
    height: 32px;
    display: flex;
    padding: 0px 0px 0px 0px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: green;
  
}

.circledName span {
    color: white;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    width: 100%;
}

.margin-right {
    margin-right: 1rem;
}

.text-middle {
    align-items: center;
    justify-content: center;
    
}
.text-middle span {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    width: 100%;
}
