.dropdown-menu {
    border: 0px;
    padding: 0px;
}

 .ct-sidebar{
    padding-left: 4vh;
 }
 
 .ct-sidebar a{
    margin-left: 1vh;
 }

.active {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background-color: rgb(245, 245, 245);
}

.active a {
    color: #212529 !important;
}